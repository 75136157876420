<template>
  <div>
    <v-bottom-navigation color="info" horizontal :background-color="color" dark>
      <v-btn to="/vecprovince/commiteevecpro">
        <span>แต่งตั้งคณะกรรมการตรวจสอบ </span>
        <v-icon>mdi-source-branch</v-icon>
      </v-btn>

      <v-btn to="/vecprovince/transference_personnel">
        <span>ผู้เสนอย้าย ตำแหน่งครู</span>
        <v-icon>mdi-source-branch</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="คณะกรรมการตรวจสอบศักยภาพของผู้ประสงค์ขอย้าย ตำแหน่งครู (การย้ายกรณีปกติ)"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" lg="6" class="text-right">             
              <div v-if="countCommits!==6">
                <v-btn
                v-if="commiteevecreports.commiteevecreportStatus !== '1'"
                right
                depressed
                color="primary"
                @click.native="commiteevecproAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>
              </div>
              

              <v-btn
                v-if="commiteevecreports.commiteevecreportStatus !== '1'"
                color="primary"
                @click.native="commiteevecproConfirm()"
                ><v-icon class="pr-2">mdi-account-check</v-icon>
                ยืนยันรายชื่อคณะกรรมการ</v-btn
              >

              <v-btn
                v-if="commiteevecreports.commiteevecreportStatus === '1'"
                color="info"
                :href="'#/vecprovince/print_committee_vp/' + user.province_ID"
                target="_blank"
                ><v-icon class="pr-2">mdi-printer</v-icon>
                พิมพ์รายชื่อคณะกรรมการ</v-btn
              >

              <div v-if="commiteevecreports.commiteevecreportFile">
                <v-btn
                  color="info"
                  :href="
                    '/HRvecfiles/' + commiteevecreports.commiteevecreportFile
                  "
                  target="_blank"
                  ><v-icon class="pr-2">mdi-printer</v-icon>
                  พิมพ์เอกสารที่แนบ</v-btn
                >
              </div>
              <div v-else>
                <v-form
                  ref="Updatecommiteevecreport_transferform"
                  lazy-validation
                  v-if="commiteevecreports.commiteevecreportStatus === '1'"
                >
                  แนบไฟล์รายชื่อคณะกรรมการ ที่ได้รับการลงลายมือชื่อรับรอง
                  <v-file-input
                    v-model="documentsLinkfiles"
                    accept=".pdf"
                    name="documentsLinkfiles"
                    color="deep-purple accent-4"
                    counter
                    label="ไฟล์ .pdf"
                    placeholder="เลือกแนบไฟล์ PDF"
                    prepend-icon="mdi-paperclip"
                    outlined
                    rounded
                    :show-size="1000"
                    :rules="rules"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    rounded
                    color="green"
                    @click="UpdateFilecommiteevecreport()"
                  >
                    <v-icon>mdi-content-save</v-icon> บันทึกไฟล์</v-btn
                  >
                </v-form>
              </div>
            </v-col>
            <v-col cols="12">
              <v-alert
                text
                dense
                color="teal"
                icon="mdi-clock-fast"
                border="left"
              >
                1. สำนักงานอาชีวศึกษาจังหวัดดำเนินการตั้งคณะกรรมการตรวจสอบ
                การตรวจสอบผู้ประสงค์ของย้าย ตำแหน่งครู บันทึกยืนยัน<br />
                2. พิมพ์รายชื่อคณะกรรมการ
                <br />
                * เมื่อกดปุ่มบันทึก ยืนยันรายชื่อคณะกรรมการ
                จะไม่สามารถแก้ไขข้อมูลใดๆ
                ได้กรุณาตรวจสอบให้ถูกต้องก่อนการบันทึกยืนยัน

                <h2 class="red--text" v-if="checkEventAss !== 21">
                  <v-icon color="red">mdi-information</v-icon>
                  เพิ่มคณะกรรมการให้ครบถ้วน กรุณาตรวจสอบรายการก่อน
                  กดยืนยันรายชื่อคณะกรรมการ
                </h2>
              </v-alert>
            </v-col>
          </v-row>
        </v-card>

        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="commiteevecpros"
          :search="search"
        >
          <template v-slot:item="{ item, index }">
            <tr>
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-center">{{ item.user_lastname }}</td>
              <td class="text-center">
                {{ parseInt(item.commiteeVecProYear) + 543 }}
              </td>
              <td class="text-center">{{ item.commiteeVecProTime }}</td>
              <td>
                {{ item.title_s + "" + item.frist_name + " " + item.last_name }}
              </td>
              <td class="text-center">
                <span v-if="item.commiteeVecProPosition === '1'">
                  ประธานกรรมการ</span
                >
                <span v-if="item.commiteeVecProPosition === '2'">
                  กรรมการ ลำดับที่ 1</span
                >
                <span v-if="item.commiteeVecProPosition === '3'">
                  กรรมการ ลำดับที่ 2</span
                >
                <span v-if="item.commiteeVecProPosition === '4'">
                  กรรมการ ลำดับที่ 3</span
                >
                <span v-if="item.commiteeVecProPosition === '5'">
                  กรรมการ ลำดับที่ 4</span
                >
                <span v-if="item.commiteeVecProPosition === '6'">
                  เลขานุการ</span
                >
              </td>
              <td class="text-center">
                <v-icon
                  v-if="item.commiteevecproStatus != '1'"
                  color="yellow"
                  @click.stop="
                    commiteevecproEdit(
                      item.commiteevecproviceID,
                      item.commiteeVecProYear,
                      item.commiteeVecProTime,
                      item.commiteeVecProIdCard
                    )
                  "
                >
                  mdi-pencil
                </v-icon>
                <v-icon color="primary" v-else>
                  mdi-checkbox-marked-circle
                </v-icon>
              </td>
              <td class="text-center">
                <v-icon
                  v-if="item.commiteevecproStatus != '1'"
                  color="red"
                  @click.stop="
                    commiteevecproDelete(
                      item.commiteevecproviceID,
                      item.commiteeVecProYear,
                      item.commiteeVecProTime,
                      item.commiteeVecProIdCard
                    )
                  "
                >
                  mdi-delete
                </v-icon>
                <v-icon color="primary" v-else>
                  mdi-checkbox-marked-circle
                </v-icon>
              </td>
            </tr>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addcommiteevecprodialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="addcommiteevecprodialog"
          persistent
          max-width="80%"
          overlay-opacity="0.6"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-clipboard-text"
              title="เพิ่มข้อมูล คณะกรรมการ"
              class="px-5 py-3 "
            >
            ปี
                          {{ periods.period_vecprovince_year }}
                          ครั้งที่ {{ periods.period_vecprovince_times }} <br />
                          กรรมการ
                          <span
                            v-if="
                              periods.period_vecprovince_type ===
                                'discuss_teacher'
                            "
                            >พิจารณาย้ายสายการสอน</span
                          >

                          <span
                            v-if="
                              periods.period_vecprovince_type ===
                                'discuss_manage'
                            "
                            >พิจารณาย้ายสายบริหาร</span
                          >
          </base-material-card>
            <v-card-text class="">
              <v-form ref="addcommiteevecproform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-row>                    
                        <v-col cols="12" md="12">
                          <v-simple-table fixed-header height="300px">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-center" width="40%">
                                    ชื่อ-นามสกุล
                                  </th>
                                  <th class="text-center" width="25%">
                                    ทำหน้าที่
                                  </th>
                                  <th class="text-center" width="35%">
                                    ข้อมูล
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="pa-2">
                                    <v-autocomplete
                                      :items="personnel_temporarys"
                                      item-text="frist_names"
                                      item-value="id_card"
                                      v-model="commiteeVecProIdCard[0]"
                                      prepend-icon="mdi-account"
                                      dense
                                      label="ค้นหา : ชื่อ-นามสกุล"
                                      request
                                      :rules="[v => !!v || '']"
                                      rounded
                                      outlined
                                      @change="id_cardSearch1()"
                                    >
                                      <template v-slot:item="{ item }">
                                        <div class="pa-2">
                                          <div class="font-weight-black">
                                            {{ item.frist_names }}
                                          </div>

                                          <div class="divTab50">
                                            {{ item.position_name }} :
                                            {{ item.college_name }}
                                          </div>
                                        </div>
                                      </template>
                                    </v-autocomplete>
                                  </td>
                                  <td>
                                    ประธานกรรมการ
                                  </td>
                                  <td>
                                    <v-alert
                                      dense
                                      text
                                      type="success"
                                      v-if="
                                        personnel_temporaryschecks1.frist_names
                                      "
                                    >
                                      {{
                                        personnel_temporaryschecks1.frist_names
                                      }}
                                      ตำแหน่ง
                                      {{
                                        personnel_temporaryschecks1.position_name
                                      }}
                                      <br />
                                      {{
                                        personnel_temporaryschecks1.college_name
                                      }}
                                    </v-alert>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="pa-2">
                                    <v-autocomplete
                                      :items="personnel_temporarys"
                                      item-text="frist_names"
                                      item-value="id_card"
                                      v-model="commiteeVecProIdCard[1]"
                                      prepend-icon="mdi-account"
                                      dense
                                      label="ค้นหา : ชื่อ-นามสกุล"
                                      request
                                      :rules="[v => !!v || '']"
                                      rounded
                                      outlined
                                      @change="id_cardSearch2()"
                                    >
                                      <template v-slot:item="{ item }">
                                        <div class="pa-2">
                                          <div class="font-weight-black">
                                            {{ item.frist_names }}
                                          </div>

                                          <div class="divTab50">
                                            {{ item.position_name }} :
                                            {{ item.college_name }}
                                          </div>
                                        </div>
                                      </template>
                                    </v-autocomplete>
                                  </td>
                                  <td>
                                    กรรมการ
                                  </td>
                                  <td>
                                    <v-alert
                                      dense
                                      text
                                      type="success"
                                      v-if="
                                        personnel_temporaryschecks2.frist_names
                                      "
                                    >
                                      {{
                                        personnel_temporaryschecks2.frist_names
                                      }}
                                      ตำแหน่ง
                                      {{
                                        personnel_temporaryschecks2.position_name
                                      }}
                                      <br />
                                      {{
                                        personnel_temporaryschecks2.college_name
                                      }}
                                    </v-alert>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="pa-2">
                                    <v-autocomplete
                                      :items="personnel_temporarys"
                                      item-text="frist_names"
                                      item-value="id_card"
                                      v-model="commiteeVecProIdCard[2]"
                                      prepend-icon="mdi-account"
                                      dense
                                      label="ค้นหา : ชื่อ-นามสกุล"
                                      request
                                      :rules="[v => !!v || '']"
                                      rounded
                                      outlined
                                      @change="id_cardSearch3()"
                                    >
                                      <template v-slot:item="{ item }">
                                        <div class="pa-2">
                                          <div class="font-weight-black">
                                            {{ item.frist_names }}
                                          </div>

                                          <div class="divTab50">
                                            {{ item.position_name }} :
                                            {{ item.college_name }}
                                          </div>
                                        </div>
                                      </template>
                                    </v-autocomplete>
                                  </td>
                                  <td>กรรมการ</td>
                                  <td>
                                    <v-alert
                                      dense
                                      text
                                      type="success"
                                      v-if="
                                        personnel_temporaryschecks3.frist_names
                                      "
                                    >
                                      {{
                                        personnel_temporaryschecks3.frist_names
                                      }}
                                      ตำแหน่ง
                                      {{
                                        personnel_temporaryschecks3.position_name
                                      }}
                                      <br />
                                      {{
                                        personnel_temporaryschecks3.college_name
                                      }}
                                    </v-alert>
                                  </td>
                                </tr>

                                <tr>
                                  <td class="pa-2">
                                    <v-autocomplete
                                      :items="personnel_temporarys"
                                      item-text="frist_names"
                                      item-value="id_card"
                                      v-model="commiteeVecProIdCard[3]"
                                      prepend-icon="mdi-account"
                                      dense
                                      label="ค้นหา : ชื่อ-นามสกุล"
                                      request
                                      :rules="[v => !!v || '']"
                                      rounded
                                      outlined
                                      @change="id_cardSearch4()"
                                    >
                                      <template v-slot:item="{ item }">
                                        <div class="pa-2">
                                          <div class="font-weight-black">
                                            {{ item.frist_names }}
                                          </div>

                                          <div class="divTab50">
                                            {{ item.position_name }} :
                                            {{ item.college_name }}
                                          </div>
                                        </div>
                                      </template>
                                    </v-autocomplete>
                                  </td>
                                  <td>กรรมการ</td>
                                  <td>
                                    <v-alert
                                      dense
                                      text
                                      type="success"
                                      v-if="
                                        personnel_temporaryschecks4.frist_names
                                      "
                                    >
                                      {{
                                        personnel_temporaryschecks4.frist_names
                                      }}
                                      ตำแหน่ง
                                      {{
                                        personnel_temporaryschecks4.position_name
                                      }}
                                      <br />
                                      {{
                                        personnel_temporaryschecks4.college_name
                                      }}
                                    </v-alert>
                                  </td>
                                </tr>

                                <tr>
                                  <td class="pa-2">
                                    <v-autocomplete
                                      :items="personnel_temporarys"
                                      item-text="frist_names"
                                      item-value="id_card"
                                      v-model="commiteeVecProIdCard[4]"
                                      prepend-icon="mdi-account"
                                      dense
                                      label="ค้นหา : ชื่อ-นามสกุล"
                                      request
                                      :rules="[v => !!v || '']"
                                      rounded
                                      outlined
                                      @change="id_cardSearch5()"
                                    >
                                      <template v-slot:item="{ item }">
                                        <div class="pa-2">
                                          <div class="font-weight-black">
                                            {{ item.frist_names }}
                                          </div>

                                          <div class="divTab50">
                                            {{ item.position_name }} :
                                            {{ item.college_name }}
                                          </div>
                                        </div>
                                      </template>
                                    </v-autocomplete>
                                  </td>
                                  <td>กรรมการ</td>
                                  <td>
                                    <v-alert
                                      dense
                                      text
                                      type="success"
                                      v-if="
                                        personnel_temporaryschecks5.frist_names
                                      "
                                    >
                                      {{
                                        personnel_temporaryschecks5.frist_names
                                      }}
                                      ตำแหน่ง
                                      {{
                                        personnel_temporaryschecks5.position_name
                                      }}
                                      <br />
                                      {{
                                        personnel_temporaryschecks5.college_name
                                      }}
                                    </v-alert>
                                  </td>
                                </tr>

                                <tr>
                                  <td class="pa-2">
                                    <v-autocomplete
                                      :items="personnel_temporarys"
                                      item-text="frist_names"
                                      item-value="id_card"
                                      v-model="commiteeVecProIdCard[5]"
                                      prepend-icon="mdi-account"
                                      dense
                                      label="ค้นหา : ชื่อ-นามสกุล"
                                      request
                                      :rules="[v => !!v || '']"
                                      rounded
                                      outlined
                                      @change="id_cardSearch6()"
                                    >
                                      <template v-slot:item="{ item }">
                                        <div class="pa-2">
                                          <div class="font-weight-black">
                                            {{ item.frist_names }}
                                          </div>

                                          <div class="divTab50">
                                            {{ item.position_name }} :
                                            {{ item.college_name }}
                                          </div>
                                        </div>
                                      </template>
                                    </v-autocomplete>
                                  </td>
                                  <td>เลขานุการ</td>
                                  <td>
                                    <v-alert
                                      dense
                                      text
                                      type="success"
                                      v-if="
                                        personnel_temporaryschecks6.frist_names
                                      "
                                    >
                                      {{
                                        personnel_temporaryschecks6.frist_names
                                      }}
                                      ตำแหน่ง
                                      {{
                                        personnel_temporaryschecks6.position_name
                                      }}
                                      <br />
                                      {{
                                        personnel_temporaryschecks6.college_name
                                      }}
                                    </v-alert>
                                  </td>
                                </tr>

                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-layout>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-col cols="12" lg="12" class="text-right">
                      <v-btn
                        large
                        color="success"
                        @click.stop="addcommiteevecproSubmit()"
                        rounded
                      >
                        <v-icon dark>mdi-content-save</v-icon
                        >&nbsp;&nbsp;บันทึก</v-btn
                      >
                      <v-btn
                        large
                        color="warning"
                        @click.stop="addcommiteevecprodialog = false"
                        rounded
                      >
                        <v-icon dark>mdi-close</v-icon>ยกเลิก
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deletecommiteevecprodialog -->
      <v-layout>
        <v-dialog
          v-model="deletecommiteevecprodialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูล"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text class="">
              <v-form ref="deletecommiteevecproform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      ยืนยันการลบข้อมูล :
                      <h3>
                        คำสั่งที่ {{ editcommiteevecpro.commiteeVecProIdCard }}
                      </h3>
                    </v-flex>
                    <v-flex xs12 md6></v-flex>
                    <v-flex xs12 md6></v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deletecommiteevecprodialog = false">
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletecommiteevecproubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editcommiteevecprodialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editcommiteevecprodialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลการศึกษา"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text>
              <v-form ref="editcommiteevecproform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-row>
                        <v-col cols="12" class="text-center">
                          ปี
                          {{ periods.period_vecprovince_year }}
                          ครั้งที่ {{ periods.period_vecprovince_times }} <br />
                          กรรมการ
                          <span
                            v-if="
                              periods.period_vecprovince_type ===
                                'discuss_teacher'
                            "
                            >พิจารณาย้ายสายการสอน</span
                          >

                          <span
                            v-if="
                              periods.period_vecprovince_type ===
                                'discuss_manage'
                            "
                            >พิจารณาย้ายสายบริหาร</span
                          >
                        </v-col>
                        <v-col cols="12" lg="6">
                          <v-select
                            v-model="editcommiteevecpro.commiteeVecProPosition"
                            :items="commiteevecpro"
                            item-text="text"
                            item-value="value"
                            prepend-icon="mdi-access-point"
                            dense
                            label="ตำแหน่ง : ประธานกรรมการ / กรรมการ"
                            request
                            :rules="[v => !!v || '']"
                            rounded
                            outlined
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <!-- <v-alert dense text type="success">
                            {{ personnel_temporaryschecks.frist_names }} ตำแหน่ง
                            {{ personnel_temporaryschecks.position_name }}
                            <br />
                            {{ personnel_temporaryschecks.college_name }}
                          </v-alert> -->
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editcommiteevecprodialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editcommiteevecproSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไขข้อมูล
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model Comfirmcommiteevecprodialog -->
      <v-layout>
        <v-dialog
          v-model="Comfirmcommiteevecprodialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="primary"
              icon="mdi-account-check"
              title="ยืนยันรายชื่อคณะกรรมการ"
              class="px-5 py-3"
            ></base-material-card>
            <v-card-text class="">
              <v-form ref="comfirmcommiteevecproform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      ยืนยันรายชื่อคณะกรรมการ :

                      <v-data-table
                        color="success"
                        :loading="loading"
                        :headers="headers"
                        :items="commiteevecpros"
                        :search="search"
                      >
                        <template v-slot:item="{ item, index }">
                          <tr>
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">
                              {{ item.user_lastname }}
                            </td>
                            <td class="text-center">
                              {{ item.commiteeVecProYear }}
                            </td>
                            <td>{{ item.commiteeVecProTime }}</td>
                            <td>
                              {{
                                item.title_s +
                                  "" +
                                  item.frist_name +
                                  " " +
                                  item.last_name
                              }}
                            </td>
                            <td class="text-center">
                              <span v-if="item.commiteeVecProPosition === '1'">
                                ประธานกรรมการ</span
                              >
                              <span v-if="item.commiteeVecProPosition === '2'">
                                กรรมการ ลำดับที่ 1</span
                              >
                              <span v-if="item.commiteeVecProPosition === '3'">
                                กรรมการ ลำดับที่ 2</span
                              >
                              <span v-if="item.commiteeVecProPosition === '4'">
                                กรรมการ ลำดับที่ 3</span
                              >
                              <span v-if="item.commiteeVecProPosition === '5'">
                                กรรมการ ลำดับที่ 4</span
                              >
                              <span v-if="item.commiteeVecProPosition === '6'">
                                เลขานุการ</span
                              >
                            </td>
                            <td class="text-center">
                              <v-icon
                                color="yellow"
                                @click.stop="
                                  commiteevecproEdit(
                                    item.commiteevecproviceID,
                                    item.commiteeVecProYear,
                                    item.commiteeVecProTime,
                                    item.commiteeVecProIdCard
                                  )
                                "
                              >
                                mdi-pencil
                              </v-icon>
                            </td>
                            <td class="text-center">
                              <v-icon
                                color="red"
                                @click.stop="
                                  commiteevecproDelete(
                                    item.commiteevecproviceID,
                                    item.commiteeVecProYear,
                                    item.commiteeVecProTime,
                                    item.commiteeVecProIdCard
                                  )
                                "
                              >
                                mdi-delete
                              </v-icon>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-flex>
                    <v-flex xs12 md6></v-flex>
                    <v-flex xs12 md6></v-flex>
                  </v-layout>
                </v-container>
              </v-form>
              <div class="red--text">
                *เมื่อกดปุ่มบันทึก ยืนยันรายการ ท่านจะไม่สามารถแก้ไขข้อมูลใดๆ
                ได้ กรุณาตรวจสอบให้ถูกต้องเรียบร้อยก่อนการบันทึกยืนยันรายการ
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="Comfirmcommiteevecprodialog = false">
                <v-icon dark>mdi-close</v-icon>ปิด
              </v-btn>
              <v-btn
                large
                color="primary"
                @click.stop="confirmcommiteevecproubmit()"
                dark
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก ยืนยันรายการ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import { Promise } from "q";
export default {
  data() {
    return {
      ApiKey: "HRvec2021",
      loading: true,
      updateImageDialog: false,
      search: "",
      addcommiteevecprodialog: false,
      editcommiteevecprodialog: false,
      deletecommiteevecprodialog: false,
      Comfirmcommiteevecprodialog: false,
      showimagedialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      currentPK: null,
      headers: [
        { text: "#", align: "center", value: "index" },
        { text: "สอจ.", align: "center", value: "commiteevecproviceID" },
        { text: "ปี", align: "center", value: "commiteeVecProYear" },
        { text: "ครั้งที่", align: "center", value: "commiteeVecProTime" },
        {
          text: "ชื่อ-นามสกุล",
          align: "left",
          value: "commiteevecproviceIDCard"
        },
        { text: "ตำแหน่ง", align: "center", value: "commiteeVecProPosition" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      pagination: {},
      commiteevecpro: [],
      addcommiteevecpro: {},
      editcommiteevecpro: [],
      commiteevecpros: [],
      commiteevecpro_sub: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      commiteevecpro: [
        { text: "ประธานกรรมการ", value: "1" },
        { text: "กรรมการ ลำดับที่ 1", value: "2" },
        { text: "กรรมการ ลำดับที่ 2", value: "3" },
        { text: "กรรมการ ลำดับที่ 3", value: "4" },
        { text: "กรรมการ ลำดับที่ 4", value: "5" },
        { text: "เลขานุการ", value: "6" }
      ],
      personnel_temporaryschecks1: [],
      personnel_temporaryschecks2: [],
      personnel_temporaryschecks3: [],
      personnel_temporaryschecks4: [],
      personnel_temporaryschecks5: [],
      personnel_temporaryschecks6: [],
      periods: [],
      personnel_temporarys: [],
      user: [],
      commiteevecproStatusC: {},
      commiteevecproStatusRP: {},
      commiteevecreports: [],
      rules: [
        value => !value || value.size < 1048576 || "ไฟล์ไม่เกิน 1 MB / 1024 KB"
      ],
      updateFilecommiteevecreports: {},
      documentsLinkfiles: null,
      transference_personnels: [],
      commiteeVecProIdCard: [],
      countCommits:[]
    };
  },

  async mounted() {
    let result;
    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    result = await this.$http.post("admin.php", {
      user_name: userSession.user_name,
      provice: "check",
      ApiKey: "HRvec2021"
    });
    this.user = result.data;
    await this.periodQuery();
    await this.commiteevecprosQueryAll();
    await this.commiteevecreportQuery();
    await this.transference_personnelQueryAll();
   /*  let checkEventAssTran = this.checkEventAssTran;
    if (checkEventAssTran == 0) {
      Swal.fire({
        icon: "warning",
        title: "ไม่พบผู้ยืนย้ายในสำนักงานอาชีวศึกษาจังหวัด",
        showConfirmButton: false,
        timer: 3000
      });
      this.$router.push("/vecprovince");
    } */
    await this.checkCommitteeCount()
  },

  methods: {
    async transference_personnelQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          province_ID: userSession.province_ID,
          time_s: this.periods.period_vecprovince_times,
          year_s: this.periods.period_vecprovince_year,
          type_move:'nm',   
        })
        .finally(() => (this.loading = false));
      this.transference_personnels = result.data;
      
    },

    async commiteevecreportQuery() {
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result;
      result = await this.$http.post("commiteevecreport.php", {
        ApiKey: this.ApiKey,
        commiteevecreportproviceID: this.user.province_ID,
        commiteevecreportYear: this.periods.period_vecprovince_year,
        commiteevecreportTime: this.periods.period_vecprovince_times
      });
      this.commiteevecreports = result.data;
    },

    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovince_enable: "1",
        period_vecprovince_type: "discuss_teacher"
      });
      this.periods = result_period.data;
      if (this.periods.period_vecprovince_enable != "1")
        this.$router.push("/vecprovince");
    },

    async id_cardSearch1() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: this.commiteeVecProIdCard[0]
      });       
      this.personnel_temporaryschecks1 = result.data;
      let idcard = this.commiteeVecProIdCard[0];   
        let found = this.commiteeVecProIdCard.filter(value => value == idcard)
        
        if(found.length >1){
          Swal.fire({
            icon: "warning",
            title: "ซ้ำ",
            showConfirmButton: false,
            timer: 1500
          });
          this.commiteeVecProIdCard[0]=""
        }
    },
    async id_cardSearch2() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: this.commiteeVecProIdCard[1]
      });
      this.personnel_temporaryschecks2 = result.data;
      let idcard = this.commiteeVecProIdCard[1];   
        let found = this.commiteeVecProIdCard.filter(value => value == idcard)
        
        if(found.length >1){
          Swal.fire({
            icon: "warning",
            title: "ซ้ำ",
            showConfirmButton: false,
            timer: 1500
          });
          this.commiteeVecProIdCard[1]=""
        }
    },
    async id_cardSearch3() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: this.commiteeVecProIdCard[2]
      });
      this.personnel_temporaryschecks3 = result.data;
      let idcard = this.commiteeVecProIdCard[2];   
        let found = this.commiteeVecProIdCard.filter(value => value == idcard)
        
        if(found.length >1){
          Swal.fire({
            icon: "warning",
            title: "ซ้ำ",
            showConfirmButton: false,
            timer: 1500
          });
          this.commiteeVecProIdCard[2]=""
        }
    },
    async id_cardSearch4() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: this.commiteeVecProIdCard[3]
      });
      this.personnel_temporaryschecks4 = result.data;
      let idcard = this.commiteeVecProIdCard[3];   
        let found = this.commiteeVecProIdCard.filter(value => value == idcard)
        
        if(found.length >1){
          Swal.fire({
            icon: "warning",
            title: "ซ้ำ",
            showConfirmButton: false,
            timer: 1500
          });
          this.commiteeVecProIdCard[3]=""
        }
    },

    async id_cardSearch5() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: this.commiteeVecProIdCard[4]
      });
      this.personnel_temporaryschecks5 = result.data;
      let idcard = this.commiteeVecProIdCard[4];   
        let found = this.commiteeVecProIdCard.filter(value => value == idcard)
        
        if(found.length >1){
          Swal.fire({
            icon: "warning",
            title: "ซ้ำ",
            showConfirmButton: false,
            timer: 1500
          });
          this.commiteeVecProIdCard[4]=""
        }
    },

    async id_cardSearch6() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: this.commiteeVecProIdCard[5]
      });
      this.personnel_temporaryschecks6 = result.data;
      let idcard = this.commiteeVecProIdCard[5];   
        let found = this.commiteeVecProIdCard.filter(value => value == idcard)
        
        if(found.length >1){
          Swal.fire({
            icon: "warning",
            title: "ซ้ำ",
            showConfirmButton: false,
            timer: 1500
          });
          this.commiteeVecProIdCard[5]=""
        }
    },


    async personnel_temporaryQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          province_ID: userSession.province_ID
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },

    async commiteevecprosQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("commiteevecpro.php", {
          ApiKey: this.ApiKey,
          commiteevecproviceID: userSession.province_ID,
          commiteeVecProYear: this.periods.period_vecprovince_year,
          commiteeVecProTime: this.periods.period_vecprovince_times
        })
        .finally(() => (this.loading = false));
      this.commiteevecpros = result.data;
    },

    //Add data
    async commiteevecproAdd() {
      this.addcommiteevecpro = {};
      this.addcommiteevecprodialog = true;
      this.personnel_temporaryQueryAll();
    },

    async addcommiteevecproSubmit() {
      if (this.$refs.addcommiteevecproform.validate()) {
        var result;
        var itemCheck;
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.addcommiteevecpro.ApiKey = this.ApiKey;
        this.addcommiteevecpro.commiteevecproviceID = userSession.province_ID;
        this.addcommiteevecpro.commiteeVecProYear = this.periods.period_vecprovince_year;
        this.addcommiteevecpro.commiteeVecProTime = this.periods.period_vecprovince_times;

         result = this.commiteeVecProIdCard.forEach(async (value, index) => {
          let posotion = index;
          this.addcommiteevecpro.commiteeVecProIdCard = value;
          this.addcommiteevecpro.commiteeVecProPosition = posotion + 1;

          result = await this.$http.post(
            "commiteevecpro.insert.php",
            this.addcommiteevecpro
          );
         
         

          if((posotion+1)==this.commiteeVecProIdCard.length){
            await this.commiteevecprosQueryAll();
         
          }
        });     
        this.checkCommitteeCount()    
        this.addcommiteevecprodialog = false;
      }
    },

    //Edit data
    async commiteevecproEdit(
      commiteevecproviceID,
      commiteeVecProYear,
      commiteeVecProTime,
      commiteeVecProIdCard
    ) {
      let result = await this.$http.post("commiteevecpro.php", {
        ApiKey: this.ApiKey,
        commiteevecproviceID: commiteevecproviceID,
        commiteeVecProYear: commiteeVecProYear,
        commiteeVecProTime: commiteeVecProTime,
        commiteeVecProIdCard: commiteeVecProIdCard
      });
      this.editcommiteevecpro = result.data;
      this.editcommiteevecprodialog = true;
    },

    async editcommiteevecproSubmit() {
      if (this.$refs.editcommiteevecproform.validate()) {
        this.editcommiteevecpro.ApiKey = this.ApiKey;
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.editcommiteevecpro.commiteevecproviceID = userSession.province_ID;
        this.editcommiteevecpro.commiteeVecProYear = this.periods.period_vecprovince_year;
        this.editcommiteevecpro.commiteeVecProTime = this.periods.period_vecprovince_times;
        let result = await this.$http.post(
          "commiteevecpro.update.php",
          this.editcommiteevecpro
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.commiteevecprosQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editcommiteevecprodialog = false;
      }
    },

    //DELETE data
    async commiteevecproDelete(
      commiteevecproviceID,
      commiteeVecProYear,
      commiteeVecProTime,
      commiteeVecProIdCard
    ) {
      let result = await this.$http.post("commiteevecpro.php", {
        ApiKey: this.ApiKey,
        commiteevecproviceID: commiteevecproviceID,
        commiteeVecProYear: commiteeVecProYear,
        commiteeVecProTime: commiteeVecProTime,
        commiteeVecProIdCard: commiteeVecProIdCard
      });
      this.editcommiteevecpro = result.data;
      this.deletecommiteevecprodialog = true;
    },

    async deletecommiteevecproubmit() {
      if (this.$refs.deletecommiteevecproform.validate()) {
        this.editcommiteevecpro.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "commiteevecpro.delete.php",
          this.editcommiteevecpro
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.commiteevecprosQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ลบข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.checkCommitteeCount()
        this.deletecommiteevecprodialog = false;
      }
    },
    async commiteevecproConfirm() {
      this.Comfirmcommiteevecprodialog = true;
    },
    async confirmcommiteevecproubmit() {
      if (this.$refs.comfirmcommiteevecproform.validate()) {
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;

        this.commiteevecproStatusC.ApiKey = this.ApiKey;
        this.commiteevecproStatusC.commiteevecproviceID =
          userSession.province_ID;
        this.commiteevecproStatusC.commiteeVecProYear = this.periods.period_vecprovince_year;
        this.commiteevecproStatusC.commiteeVecProTime = this.periods.period_vecprovince_times;
        this.commiteevecproStatusC.commiteevecproStatus = "1";

        this.commiteevecproStatusRP.ApiKey = this.ApiKey;
        this.commiteevecproStatusRP.commiteevecreportproviceID =
          userSession.province_ID;
        this.commiteevecproStatusRP.commiteevecreportYear = this.periods.period_vecprovince_year;
        this.commiteevecproStatusRP.commiteevecreportTime = this.periods.period_vecprovince_times;
        this.commiteevecproStatusRP.commiteevecreportStatus = "1";
        this.commiteevecproStatusRP.commiteevecreportDateTime = this.date_today_cal;

        let result = await this.$http.post(
          "commiteevecpro.update.status.php",
          this.commiteevecproStatusC
        );

        let result_report = await this.$http.post(
          "commiteevecreport.insert.php",
          this.commiteevecproStatusRP
        );
        if (result.data.status == true && result_report.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "บันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          await this.commiteevecprosQueryAll();
          await this.commiteevecreportQuery();
        } else {
          Swal.fire({
            icon: "warning",
            title: "บันทึกข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.checkCommitteeCount()
        this.Comfirmcommiteevecprodialog = false;
      }
    },
    async UpdateFilecommiteevecreport() {
      let result = "";
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let uploaded = null;
      this.updateFilecommiteevecreports.ApiKey = this.ApiKey;
      this.updateFilecommiteevecreports.commiteevecreportproviceID =
        userSession.province_ID;
      this.updateFilecommiteevecreports.commiteevecreportYear = this.periods.period_vecprovince_year;
      this.updateFilecommiteevecreports.commiteevecreportTime = this.periods.period_vecprovince_times;
      this.updateFilecommiteevecreports.commiteevecreportFileDate = this.date_today_cal;

      if (this.$refs.Updatecommiteevecreport_transferform.validate()) {
        if (this.documentsLinkfiles != "") {
          let formData = new FormData();
          let filename =
            this.updateFilecommiteevecreports.commiteevecreportproviceID +
            this.periods.period_vecprovince_year +
            this.periods.period_vecprovince_times +
            "committeevp.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.updateFilecommiteevecreports.commiteevecreportFile = filename;
          }
          uploaded = true;
        } else {
          uploaded = false;
        }
      }
      result = await this.$http.post(
        "commiteevecreport.update.php",
        this.updateFilecommiteevecreports
      );
      ;
      if (result.data.status || uploaded) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        await this.commiteevecprosQueryAll();
        await this.commiteevecreportQuery();
      } else {
        if (!uploaded) {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ไฟล์ที่แนบไม่ถูกต้อง";
          this.snackbar.show = true;
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
    },
    checkCommitteeCount() {
      let data = this.commiteevecpros;
      let sum = 0;
      data.forEach(value => {
        sum = sum + 1;
      });
    this.countCommits= sum;
    },
  },

  computed: {
    checkEventAssTran() {
      let data = this.transference_personnels;
      let sum = 0;
      data.forEach(value => {
        sum = sum + 1;
      });
      return sum;
    },

    

    checkEventAss() {
      let data = this.commiteevecpros;
      let sum = 0;
      data.forEach(value => {
        sum = sum + parseInt(value[4]);
      });
      return sum;
    },

    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "#1D386A";
    },
    date_today_cal() {
      let today = new Date();
      return today;
    }
  }
};
</script>
<style>
.v-data-table thead th {
  font-size: 16px !important;
}
.divTab50 {
  text-justify: inter-word;
  text-indent: 20px;
  line-height: 1;
}
</style>
